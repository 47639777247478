body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.App {
  display: flex;
  max-width: 1920px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

html, body {
  margin: 0;
  padding: 0;
}

#video-wrapper {
  z-index: 1;
}

video {
  background-color: transparent;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}